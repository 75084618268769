<template>
  <div>
    <b-row>
      <b-col>
        <b-card no-body>
          <b-card-header>
            <div>
              <b-card-title>รายละเอียดการเล่นเกม</b-card-title>
            </div>
          </b-card-header>
          <hr class="m-0">
          <b-card-body>
            <b-form-group
              label="วันที่"
              label-for="วันที่"
              label-cols="6"
              label-class="text-secondary"
            >
              <b-form-text class="text-right text-weight-bolder">
                <span class="text-white">
                  {{ detail.date | date }}
                </span>
                <span class="text-muted">
                  ( {{ detail.date | time }})
                </span>
              </b-form-text>
            </b-form-group>
            <b-form-group
              label="ชื่อเกม"
              label-for="ชื่อเกม"
              label-cols="6"
              label-class="text-secondary"
            >
              <b-form-input
                id="ชื่อเกม"
                :value="detail.gameName"
                class="text-right text-white text-weight-bolder"
                plaintext
              />
            </b-form-group>
            <b-form-group
              label="Round ID"
              label-for="roundId"
              label-cols="6"
              label-class="text-secondary"
            >
              <b-form-input
                id="roundId"
                :value="detail.roundId"
                class="text-right text-white text-weight-bolder"
                plaintext
              />
            </b-form-group>
            <b-form-group
              label="Username ของผู้เล่น"
              label-for="username"
              label-cols="6"
              label-class="text-secondary"
            >
              <b-form-input
                id="username"
                :value="detail.username"
                class="text-right text-white text-weight-bolder"
                plaintext
              />
            </b-form-group>
            <b-form-group
              label="ผลการเล่นเกม"
              label-for="type"
              label-cols="6"
              label-class="text-secondary"
            >
              <b-form-input
                id="type"
                :value="displayGameResult(detail.type)"
                class="text-right text-weight-bolder"
                :class="{
                  'text-success': detail.type === 'win',
                  'text-danger': detail.type === 'loss',
                  'text-primary': detail.type === 'bot',
                }"
                plaintext
              />
            </b-form-group>
            <b-form-group
              label="จำนวนเงินที่เดิมพัน"
              label-for="betAmount"
              label-cols="6"
              label-class="text-secondary"
            >
              <b-form-input
                id="betAmount"
                :value="detail.betAmount | currency"
                class="text-right text-white text-weight-bolder"
                plaintext
              />
            </b-form-group>
            <b-form-group
              label="เงินรางวัลที่ได้รับ (หัก % แล้ว)"
              label-for="prize"
              label-cols="6"
              label-class="text-secondary"
            >
              <b-form-input
                id="prize"
                :value="detail.prize | currency"
                class="text-right text-weight-bolder"
                plaintext
              />
            </b-form-group>
            <b-form-group
              label="ค่าธรรมเนียมที่โดนหัก"
              label-for="fee"
              label-cols="6"
              label-class="text-secondary"
            >
              <b-form-input
                id="fee"
                :value="detail.fee | currency"
                class="text-right text-weight-bolder"
                plaintext
              />
            </b-form-group>
            <b-form-group
              label="ผู้เล่นคนอื่นในห้อง"
              label-for="players"
              label-cols="6"
              label-class="text-secondary"
            >
              <b-form-input
                id="players"
                :value="'ไม่มีชื่อ'"
                class="text-right text-white text-weight-bolder"
                plaintext
              />
            </b-form-group>
            <b-form-group
              label="ผู้เล่นชนะในห้อง"
              label-for="winner"
              label-cols="6"
              label-class="text-secondary"
            >
              <b-form-input
                id="winner"
                :value="'ไม่มีชื่อ'"
                class="text-right text-white text-weight-bolder"
                plaintext
              />
            </b-form-group>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  data() {
    return {}
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.request.isGettingRequestDetail,
    }),
    ...mapGetters(['requestDetail']),
    detail() {
      return {
        id: '3',
        roundId: 'roundId',
        gameName: 'gameName',
        betAmount: 100,
        prize: 0,
        fee: 0,
        date: new Date(),
        username: 'username',
        type: 'bot',
        agent: 'agent',
      }
    },
    historyId() {
      const { query } = this.$route
      return query.id ? query.id : ''
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    ...mapActions(['getGameHistoryDetail']),
    fetchData() {
      // this.getGameHistoryDetail(this.historyId)
    },
    displayGameResult(result) {
      switch (result) {
        case 'win':
          return 'ชนะเดิมพัน'
        case 'loss':
          return 'แพ้เดิมพัน'

        case 'bot':
          return 'บอทชนะ'

        default:
          return result
      }
    },
  },
}
</script>
